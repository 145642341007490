export function useDynamicIcon(path: 'currency' | 'flags') {
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();
  const iconUrl = ref(`${gamehubCdn}/${path}`);

  return {
    iconUrl,
  };
}
